<template>
  <transition name="slide">
    <div class="feedback">
      <h :isback="true">
        <span class="name">意见反馈</span>
      </h>
      <div class="main">
        <cube-textarea
          v-model="value"
          placeholder="请输入反馈内容"
          :maxlength="200"
        />
        <div class="btn-wrap">
          <cube-button @click="submitFeedback">确定</cube-button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { feedback } from "@/api/user";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    h
  },
  data() {
    return {
      value: ""
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    submitFeedback() {
      if (this.value == "") {
        this.toast("请输入反馈内容");
        return;
      }
      const params = {
        userId: this.user.id,
        mobile: this.user.phone,
        userName: this.user.nickName,
        content: this.value
      };
      feedback(params).then(res => {
        if (!res) {
          return;
        }
        this.$createDialog({
          type: "alert",
          title: "提示",
          content: res.message,
          icon: "cubeic-alert"
        }).show();
        this.value = "";
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.feedback
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #f0f0f0
  z-index 11
  .main
    margin 15px
    .btn-wrap
      margin 30px 15px 0
      .cube-btn
        padding 10px
        background linear-gradient(to right, #1A62DB, #2A9BFB)
        border-radius 25px
        color #fff
</style>
